import React from "react";
import NavBar from "../components/navbar/navbar"
import {Container, Offcanvas} from "react-bootstrap";
import update from "immutability-helper";
import PageBar from "../components/pagebar/pagebar";
import ContentPane from "../components/contentPane/contentPane";

export default class BasePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            toolsOffCanvas: {
                open: false,  // Whether the Tools off-canvas is open or not
                title: "", // The title of the off-canvas when it opens
                description: "",
                content: null  // The JSX content that will be in the off-canvas
            }
        }

        this.toggleToolsOffCanvas = this.toggleToolsOffCanvas.bind(this)
    }

    // Toggle opening or closing the tools off-canvas
    // The content passed in needs to be a react componnet with a static attr "title"
    toggleToolsOffCanvas(content = null) {
        let newStateInfo = {
            open: {$set: false},  // Whether the Tools off-canvas is open or not
            title: {$set: ""}, // The title of the off-canvas when it opens
            description: {$set: ""},
            content: {$set: null}  // The JSX content that will be in the off-canvas
        }

        // TODO: Idiot check to ensure it's a react type with our required static prop
        if (content !== null) {
            newStateInfo = {
                open: {$set: true},  // Whether the Tools off-canvas is open or not
                title: {$set: content.type.title || "Hey, set a title static attr!"}, // Set as a static title="" on the clonedElement
                description: {$set: content.type.description || null},  // Set our description
                content: {$set: content}  // The JSX content that will be in the off-canvas
            }
        }

        this.setState(prev => ({
            toolsOffCanvas: update(prev.toolsOffCanvas, newStateInfo)
        }))
    }

    render() {
        return <div id={"wrapper"}>
            <div id={"pageWrapper"}>

                <NavBar basePage={this} />

                <PageBar
                    pageTitle={this.props.pageTitle}
                    pageCrumbs={this.props.pageCrumbs}
                    pageDescription={this.props.pageDescription}
                />

                <ContentPane>
                    <Container fluid>
                        { this.props.children }
                    </Container>
                </ContentPane>

                {/*The Toolbox off-canvas slide-pane thingy*/}
                <Offcanvas
                    show={this.state.toolsOffCanvas.open}
                    onHide={this.toggleToolsOffCanvas}
                    scroll={true}
                    backdrop={true}
                    placement={"end"}
                    style={{width:"80%"}}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            {this.state.toolsOffCanvas.title}
                            {
                                this.state.toolsOffCanvas.description && <>
                                    <br />
                                    <span style={{fontSize:"16px"}}>
                                        {this.state.toolsOffCanvas.description}
                                    </span>
                                </>
                            }
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {this.state.toolsOffCanvas.content}
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    }
}