import {Component} from "react";
import {Card} from "react-bootstrap";

export default class ContentPane extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Card style={{borderColor: "var(--bs-gray-200)"}}>
            <Card.Body>
                {this.props.children}
            </Card.Body>
        </Card>
    }
}