import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";

// Basic imports for pages
import "./custom.css"
import Home from "./pages/home/home";

// Error pages and related
import ErrorPage404 from "./pages/errors/404";
import KnowledgeBase from "./pages/growInfo/knowledgeBase/knowledgeBase";
import Glossary from "./pages/help/glossary/glossary";
import CloudDashboard from "./pages/cloud/dashbaord/dashboard";

console.info(`Using React version ${React.version}`)

const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route exact path={"/"} element={<Home />} />

            <Route exact path={"/cloud/dashboard"} element={<CloudDashboard />} />

            {/*Grow Info related pages*/}
            <Route exact path={"/help/kb"} element={<KnowledgeBase />} />
            <Route exact path={"/help/glossary"} element={<Glossary />} />

            <Route path={"*"} element={<ErrorPage404 />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
