import {Component} from "react";
import {PageBarStyle} from "./style";
import {Col} from "react-bootstrap";

export default class PageBar extends Component {

    constructor(props) {
        super(props);

        this.renderBreadcrumbs = this.renderBreadcrumbs.bind(this)
    }

    renderBreadcrumbs() {
        return <ol className={"breadcrumb float-end"} style={{marginTop: "32px", marginRight:"20px"}}>
            <li className={"breadcrumb-item"} key={"crumb-home"}>Home</li>
            {
                this.props.pageCrumbs && this.props.pageCrumbs.map(crumb => {
                    return <li className={"breadcrumb-item"} key={`crumb-${crumb.toString()}`}>
                        {crumb}
                    </li>
                })
            }
        </ol>
    }

    render() {
        return <PageBarStyle
            className={"row wrapper"}
            name={"pageBar"}
            style={{
                paddingBottom:0,
                paddingRight:0,
                paddingLeft: "20px",
                marginLeft: "-20px",
                marginRight: "-20px",
                marginBottom:"20px"
            }}
        >
            <Col style={{paddingBottom:"20px"}}>
                <h2>{this.props.pageTitle}</h2>
                {
                this.props.pageDescription && <div style={{fontSize:"14px"}}>
                        {this.props.pageDescription}
                </div>
                }
            </Col>
            <Col>
                {this.renderBreadcrumbs()}
            </Col>
        </PageBarStyle>
    }
}