import styled from "styled-components"

export const PageBarStyle = styled.div`

border-top: 0;
padding: 0 10px 20px 10px;
//background-color: ${({ theme }) => theme.pageHeadingBackground};
border-bottom: none !important;
box-shadow: 0 6px 4px -6px rgba(0, 0, 0, 0.14);

//background-image: url('${({ theme }) => theme.imagePageHeaderBackground}');
//background-size: 60%;
//background-repeat: no-repeat;
//background-position: right center;

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb > li a {
  color: inherit;
}

.breadcrumb > .active {
  color: inherit;
}
  
.breadcrumb-item,
.breadcrumb-item::before {
  font-size: 13px;
  font-weight: 300;
}
  
.breadcrumb-item + .breadcrumb-item::before {
  content: "\\00B7";
}

h2 {
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 100;
}

`;