import React from "react";
import {Col, Row} from "react-bootstrap";
import BasePage from "../basePage";

export default class ErrorPage404 extends React.Component {
    render() {
        return <BasePage
            pageTitle={"Did you lose what you're looking for?"}
            pageCrumbs={["Errors", "Not Found"]}
        >
            <Row>
                <Col>
                    <p>The thing that you're looking for couldn't be found. Where'd you put it?!</p>
                </Col>
            </Row>
        </BasePage>
    }
}