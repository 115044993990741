import {Component} from "react";
import BasePage from "../../basePage";
import {API_HOST} from "../../../lib/growcloud";

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, ReferenceLine
} from 'recharts';
import moment from "moment/moment";
import {Card, Col, Row} from "react-bootstrap";



export default class CloudDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ambientData: [],
            mediumData: [],
            dataLoading: true
        }

        this.getAPIData = this.getAPIData.bind(this)
        this.renderContent = this.renderContent.bind(this)
        this.renderLatest = this.renderLatest.bind(this)
    }

    componentDidMount() {
        this.getAPIData(0, true)
    }

    getAPIData(lookback=0, showLoading=true) {

        // Ensure our dataLoading is set to true to show the loading bar
        if (showLoading)
            this.setState({dataLoading: true})

        // If we don't specify a lookback, assume we need to look for it in the URL Params
        if (lookback === 0) {
            const queryParams = new URLSearchParams(window.location.search);
            lookback = queryParams.get('lookback');

            // If we got a null (eg: no var defined in the URL), default to 1 day lookback
            if (lookback === null)
                lookback = 1
        }

        fetch(`${API_HOST}/ambient/9bb1f8dbeb0924fb73c1cd0b7609481b4d0f71ef/historical?lookback=${lookback}`)
            .then(response => response.json())
            .then(data => this.setState({ambientData: data.items, dataLoading: false}))

        fetch(`${API_HOST}/medium/9bb1f8dbeb0924fb73c1cd0b7609481b4d0f71ef/historical?lookback=${lookback}`)
            .then(response => response.json())
            .then(data => this.setState({mediumData: data.items, dataLoading: false}))
    }

    renderLatest(readings) {
        let cards = []
        Object.entries(readings.readings).forEach(reading => {
            const [_, elem] = reading
            cards.push(<Col>
                <Card
                    bg={"Secondary"}
                    text={"dark"}
                    className="mb-2"
                    key={elem.slug + elem.value}
                >
                    <Card.Body>
                        <Card.Title>{elem.description.includes('Dissolved') ? "TDS" : elem.description}</Card.Title>
                        <Card.Text>
                            {elem.value} {elem.scale}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>)
        })
        return cards
    }

    renderContent() {

        let ambientData = []
        this.state.ambientData && this.state.ambientData.forEach((elem) => {  // Defaults to an empty array, nothing to enumerate
            ambientData.push({
                timestamp: elem.timestamp,
                humidity: elem.readings.humidity.value,
                temperature: elem.readings.temperature.value,
            })
        })
        ambientData = ambientData.reverse()

        let mediumData = []
        this.state.mediumData && this.state.mediumData.forEach((elem) => {  // Defaults to an empty array, nothing to enumerate
            mediumData.push({
                timestamp: elem.timestamp,
                ec: elem.readings.ec.value,
                ph: elem.readings.ph.value,
                tds: elem.readings.tds.value,
                temperature: elem.readings.temperature.value,
            })
        })
        mediumData = mediumData.reverse()

        return <>
            <Row style={{marginTop: "12px", marginBottom:"20px"}}>
                {this.state.ambientData.length && this.renderLatest(this.state.ambientData[0])}
                {this.state.mediumData.length && this.renderLatest(this.state.mediumData[0])}
            </Row>

            <h2>Primary Readings</h2>
            <Row>
                <Col>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={mediumData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30} />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="left" domain={[5.25, 6.75]}></YAxis>
                            <ReferenceLine yAxisId="left" y="5.8" stroke="#8884d84d" strokeDasharray="3 3" />
                            <Line yAxisId="left" dataKey="ph" stroke="#8884d8" dot={false} activeDot={{ r: 5 }} />

                            <YAxis yAxisId="right" domain={[750, 1300]} orientation="right" />
                            <ReferenceLine yAxisId="right" y="1100" stroke="#82ca9d80" strokeDasharray="3 3" />
                            <Line yAxisId="right" dataKey="tds" stroke="#82ca9d" dot={false} activeDot={{ r: 5 }}/>


                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            <h2>Ambient Readings</h2>
            <Row>
                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={ambientData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30}>
                            </XAxis>

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="left" domain={[15, 30]}></YAxis>
                            <Line yAxisId="left" dataKey="temperature" stroke="#8884d8" dot={false} activeDot={{ r: 5 }} />

                        </LineChart>
                    </ResponsiveContainer>
                </Col>

                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={ambientData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30} />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="right" domain={[35, 60]} orientation="right" />
                            <Line yAxisId="right" dataKey="humidity" stroke="#82ca9d" dot={false} activeDot={{ r: 5 }}/>

                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            <h2>DWC Readings</h2>
            <Row>
                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={mediumData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30} />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="left" domain={[15, 30]}></YAxis>
                            <Line yAxisId="left" dataKey="temperature" stroke="#8884d8" dot={false} activeDot={{ r: 5 }} />

                        </LineChart>
                    </ResponsiveContainer>
                </Col>

                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={mediumData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30} />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="right" domain={[750, 1400]} orientation="right" />
                            <Line yAxisId="right" dataKey="tds" stroke="#82ca9d" dot={false} activeDot={{ r: 5 }}/>

                        </LineChart>
                    </ResponsiveContainer>
                </Col>

            </Row>

            <Row>
                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={mediumData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30} />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="left" domain={[5, 7]}></YAxis>
                            <Line yAxisId="left" dataKey="ph" stroke="#8884d8" dot={false} activeDot={{ r: 5 }} />

                        </LineChart>
                    </ResponsiveContainer>
                </Col>

                <Col>
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart
                            data={mediumData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timestamp" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} minTickGap={30}  />

                            <Tooltip />
                            <Legend />

                            <YAxis yAxisId="right" domain={[1400, 2400]} orientation="right" />
                            <Line yAxisId="right" dataKey="ec" stroke="#82ca9d" dot={false} activeDot={{ r: 5 }}/>

                        </LineChart>
                    </ResponsiveContainer>
                </Col>

            </Row>

        </>
    }

    render() {

        return <BasePage
            pageTitle={"Growroom Dashboard"}
            pageCrumbs={["Cloud", "Dashboard"]}
        >
            {this.state.mediumData.length && this.state.ambientData.length ?
                this.renderContent() :
                "Loading, please stand by..."
            }

        </BasePage>

    }
}