import update from "immutability-helper";
import {Component} from "react";
import {
    Card,
    Col,
    Container,
    FloatingLabel,
    Form,
    FormControl,
    Row, Table
} from "react-bootstrap";
import ContentPane from "../../../contentPane/contentPane";

export default class LucasMethodCalc extends Component {

    static title = "Lucas Formula"
    static description = "This calc lets you quickly calculate how to mix your Lucas Formula batch"

    static conversionFactorTspToMl = 4.929  // Number of ML in a Tsp
    static conversionFactorGalToLitre = 3.79  // Number of L in a G, per the GH Feed Sheet

    constructor(props) {
        super(props);

        this.state = {
            errors: {
                batchSizeInvalid: false
            },
            options: {
                baseChart: "Aggressive",  // Must match one of the .values from a this.constructor.feedingCharts
                batchSize: 5,
                batchSizeUnits: "Gal",  // One of Gal or L
                growthStage: "eg",
                batchStrength: 100, // Percentage multiplier
                dosingUnits: "ml",  // One of ml or tsp
            }
        }

        this.updateOptions = this.updateOptions.bind(this)
        this.updateErrors = this.updateErrors.bind(this)
        this.calcValue = this.calcValue.bind(this)
        this.setBaseChart = this.setBaseChart.bind(this)
        this.setBatchSizeUnits = this.setBatchSizeUnits.bind(this)
        this.setDosingUnit = this.setDosingUnit.bind(this)
        this.setBatchSize = this.setBatchSize.bind(this)
        this.setGrowthStage = this.setGrowthStage.bind(this)
        this.setBatchStrength = this.setBatchStrength.bind(this)
    }

    // Change which chart we're using as a base
    setBaseChart(e) {
        this.updateOptions("baseChart", e.target.value)
    }

    setBatchSizeUnits(e) {
        this.updateOptions("batchSizeUnits", e.target.value)
    }

    setDosingUnit(e) {
        this.updateOptions("dosingUnits", e.target.value)
    }

    setBatchSize(e) {
        e.target.validity.valid ?
            this.setState((prev) => ({
                options: update(prev.options, {batchSize: {$set: e.target.value}}),
                errors: update(prev.errors, {batchSizeInvalid: {$set: false}})
            })) :
            this.updateErrors("batchSizeInvalid", true)
    }

    setGrowthStage(e) {
        this.updateOptions("growthStage", e.target.value)
    }

    setBatchStrength(e) {
        this.updateOptions("batchStrength", e.target.value)
    }

    render() {

        return <ContentPane>
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Header>Settings</Card.Header>
                        <Card.Body>
                            <Form>

                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Select a base chart" className="mb-3">
                                            <Form.Select
                                                onChange={this.setBaseChart}
                                                defaultValue={this.state.options.baseChart}
                                            >
                                                {
                                                    this.constructor.feedingCharts.map(c => {
                                                        return <option value={c.name} key={c.name}>{c.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>Mix Recipe</Card.Header>
                        <Card.Body>

                            <Row>
                                <Col>
                                    The base chart values for this calculator were pulled off of the <a href={"https://edge.generalhydroponics.com/www/uploads/20210121184307/FloraSeries-Custom-Feed-Charts.pdf"} target={"_top"}>feeding chart from the GH website</a>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ContentPane>
    }

}
