import {Component} from "react";
import BasePage from "../../basePage";

export default class KnowledgeBase extends Component {

    render() {
        return <BasePage
            pageTitle={"The Knowledge Notepad"}
            pageCrumbs={["Help Center", "Knowledge Notepad"]}
        >
            <h3>Silicon Additives (Si)</h3>
            <p>
                To begin with, lets clear something up - it's <em>silicon</em>, and <strong>not</strong> <em>silica</em>
            </p>
            <p>
                <em>Silica</em> is an insoluble compound molecule, SiO<sub>2</sub> (<em>Silicon Dioxide</em>).
                Being insoluble, it's not able to be absorbed by plants.
            </p>
            <p>
                Silicon, an element, is a singular atom. When combined with other atoms, a compound is formed.
                Silcic Acid (H<sub>4</sub>SiO<sub>4</sub>) is the most common and readily absorbed form of Silicon used as a nutrient additive.
            </p>
            <h5>Benefits of Silicon Additives</h5>
            <p>
                Silicon is a great addition to your nutrient mix. It not only aids in cell wall construction and strength,
                but also improves resilience to heat and drought, and has been shown to improve resistance (tolerance)
                to things like insects and fungal infections (think powdery mildew). Additionally, Silicon has also
                shown to improve resistance to toxicity from metallic elements (Mg, Fe, Ph, Al)
            </p>
            {/*<p>*/}
            {/*    <blockquote className="blockquote">*/}
            {/*        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a*/}
            {/*            ante.</p>*/}
            {/*        <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source*/}
            {/*            Title</cite></footer>*/}
            {/*    </blockquote>*/}
            {/*</p>*/}


            <h3>Nutrient Solution Strength</h3>
            <p>
                Solution strength has to be paid attention to to ensure that it's appropriate for the demands of a given plant
            </p>
            <p>
                As a general rule of thumb, the younger a plant, the weaker its nutrient solution should. That said, once a plant has established,
                full-strength is generally fine.
            </p>

            <h3>Brown Roots & Root Rot</h3>
            <p>
                In general, white roots are to be desired, and any discoloration indicates a potential issue. That said,
                at least when using General Hydroponics Micro, roots will indeed turn a light shade of brown.
                This is normal, and especially pronounced when used in conjunction with Floralicious Plus.
            </p>
            <p>
                Root Rot is indeed undesirable, and the most easily identified symptom is brown roots.
            </p>
            <p>
                When you're looking at your roots, don't just look at their color and decide off of that. You need to
                look at the rest of the picture. If the roots are slimy and fuzzy, or are turning black, or there's lots
                of detritus of broken off roots floating around, or any kind of foul smell (not imparted by your nutes) -
                these would be indicators of potential root rot.
            </p>
            <p>
                The temperature of your nutrient solution plays a big role in preventing root rot. Especially with DWC
                setups, it's important to keep your solution between 60F and 70F to discourage bad microbial growth
            </p>
            <p>
                Another important factor is ensuring adequate oxygenation, and movement. Stagnation of solution leads to
                a lack of oxygen, which fosters anaerobic growth. Things like "sight tubes" on reservoirs are common
                offenders of this practice. Keeping an air stone bubbling away in your reservoir (and top off tanks) ensures
                that there is constant churn, and plenty of oxygen in the mix.
            </p>

        </BasePage>
    }
}