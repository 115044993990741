import React from "react";
import {Button, Container, Form, FormControl, Navbar, Nav, NavDropdown} from "react-bootstrap";

import GHNuteCalc from "../calculators/nutrients/generalHydroponics/generalHydroponics";
import {NavBarStyle} from "./style";
import {Link} from "react-router-dom";
import LucasMethodCalc from "../calculators/nutrients/generalHydroponics/lucasMethod";

export default class NavBar extends React.Component {

    constructor(props) {
        super(props);

        this.handleOpenGHCalc = this.handleOpenGHCalc.bind(this)
        this.handleOpenLucasCalc = this.handleOpenLucasCalc.bind(this)
    }

    handleOpenGHCalc() {
        this.props.basePage.toggleToolsOffCanvas(React.cloneElement(<GHNuteCalc />))
    }

    handleOpenLucasCalc() {
        this.props.basePage.toggleToolsOffCanvas(React.cloneElement(<LucasMethodCalc />))
    }

    render() {
        return <NavBarStyle>
            <Navbar
                name={"navBar"}
                expand="lg"
                style={{marginLeft: "-20px", marginRight: "-20px"}}
            >
                <Container fluid style={{marginLeft: "10px"}}>
                    <Navbar.Brand>
                        <Link to={"/"} className={"dropdown-item"} name={"brandLink"}>
                            <img
                                src="/images/interface/cannabrand.png"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="Microgrow Site Icon"
                            />&nbsp;&nbsp;
                            Microgrow
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '100px', marginLeft: "auto" }}
                            navbarScroll
                        >

                            <Link to={"/cloud/dashboard"} className={"nav-link"}>Growroom Dashboard</Link>

                            {/*<NavDropdown title="Guides" id="navbarGrowGuidesDropdown">*/}

                            {/*    <Link to={"/guides/hydroponics"} className={"dropdown-item"} disabled>Hydroponics</Link>*/}
                            {/*    <Link to={"/guides/plants"} className={"dropdown-item"}>Plants</Link>*/}
                            {/*    <Link to={"/guides/photosynthesis"} className={"dropdown-item"}>Photosynthesis</Link>*/}
                            {/*    <Link to={"/guides/nutrients"} className={"dropdown-item"}>Nutrients</Link>*/}
                            {/*    <Link to={"/guides/watering"} className={"dropdown-item"}>Water & Irrigation</Link>*/}
                            {/*    <NavDropdown.Divider />*/}
                            {/*    <Link to={"/guides/cloning"} className={"dropdown-item"}>Cloning</Link>*/}
                            {/*    <Link to={"/guides/terpenes"} className={"dropdown-item"}>Terpenes</Link>*/}

                            {/*</NavDropdown>*/}

                            <NavDropdown title="Help Center" id="navbarHelpCenter">

                                <Link to={"/help/glossary"} className={"dropdown-item"}>Glossary</Link>
                                <NavDropdown.Divider />
                                <Link to={"/help/kb"} className={"dropdown-item"}>Kitchen Sink of Knowledge</Link>
                                {/*<Link to={"/kb"} className={"dropdown-item"}>Knowledge Base</Link>*/}
                                {/*<Link to={"/kb"} className={"dropdown-item"}>Knowledge Base</Link>*/}

                            </NavDropdown>

                            <NavDropdown title="Tools & Calculators" id="navbarToolsAndCalcsDropdown" align={"end"}>
                                <NavDropdown.Item onClick={this.handleOpenGHCalc}>General Hydroponics Nutrient Calculator</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>
                        {/*<Form className="d-flex">*/}
                        {/*    <FormControl*/}
                        {/*        type="search"*/}
                        {/*        placeholder="Search"*/}
                        {/*        className="me-2"*/}
                        {/*        aria-label="Search"*/}
                        {/*    />*/}
                        {/*    <Button variant="outline-success">Search</Button>*/}
                        {/*</Form>*/}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </NavBarStyle>
    }
}