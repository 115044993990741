import {Component} from "react";
import BasePage from "../../basePage";
import {Card, Col, Row} from "react-bootstrap";

export default class Glossary extends Component {

    static glossaryData = [
        {
            "word": "Buffer",
            "deff": "A buffer, context depending, is something that resists change. Often, you'll hear the word buffer " +
                "in reference to growing in dirt, vs hydro/cocoa. Dirt acts as a buffer - that is, it has a capacity to " +
                "absorb mistakes of the caretaker, to an extent. Because of this, dirt can be a more forgiving first-time " +
                "grow medium. The opposite is true for hydro - there is nothing to buffer against swings in pH, salinity, " +
                "etc since the roots are in direct contact with the nutrients. This is what makes hydro a bit more " +
                "complicated than dirt for first-time growers",
        },
        {
            "word": "Hydroponics",
            "deff": "Simply put, Hydroponics is the practice of growing plants without dirt, using a variety of methods and means",
        },
        {
            "word": "Nutrient Solution",
            "deff": "The pH and EC adjusted mixture of water and nutrients that provides everything a plant needs",
        },
        {
            "word": "DWC - Deep Water Culture",
            "deff": "Deep Water Culture is a style of Hydroponics, where the roots are permanently suspended in the " +
                "nutrient solution. This style of hydro system works great for larger plants that grow large root masses",
        },
    ]

    constructor(props) {
        super(props);

        this.renderDefs = this.renderDefs.bind(this)
        this.dynamicSort = this.dynamicSort.bind(this)
    }

    renderDefs(deffs) {
        return deffs.map(deff => {
            return <p>
                <h3>{deff.word}</h3>
                <hr />
                {deff.deff}
            </p>
        })
    }

    /**
    * Lets us quickly sort an array of objects alphabetically by key value
    *
    * @param {String} property Key of the object to sort.
    */
    dynamicSort(property) {
        let sortOrder = 1;

        if(property[0] === "-") {  // Check for descending order
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if (sortOrder === -1)
                return b[property].localeCompare(a[property]);
            return a[property].localeCompare(b[property]);
        }
    }

    render() {

        let firstHalf = Math.ceil(this.constructor.glossaryData.length / 2)
        let secondHalf = this.constructor.glossaryData.length - firstHalf
        let sorted = this.constructor.glossaryData.sort(this.dynamicSort("word"))

        return <BasePage
            pageTitle={"Glossary"}
            pageCrumbs={["Help Center", "Glossary"]}
        >
            <Row>
                <Col>
                    {this.renderDefs(sorted.slice(0, firstHalf))}
                </Col>
                <Col>
                    {this.renderDefs(sorted.slice(firstHalf))}
                </Col>
            </Row>
        </BasePage>
    }
}