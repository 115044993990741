import React from "react";
import BasePage from "../basePage";
import {Col, Row} from "react-bootstrap";

// const splashImage = require("./splashBanner.jpg")

export default class Home extends React.Component {

    render() {
        return <BasePage
            pageTitle={"Welcome"}
        >
            <Row>
                <Col>
                    <p>
                        For now, as we continue to add content, this site is just a collection of indoor grow information
                        and tools that we've created to make our lives easier.
                    </p>
                    <p>
                        The intent of this site is to catalogue and organize as much information about growing plants indoors as we're able.
                        For the most part, the focus will be on hydroponics (cocoa, mineral wool, dwc, etc), though general info will apply across the board of course
                    </p>
                </Col>
            </Row>
        </BasePage>
    }
}