import update from "immutability-helper";
import {Component} from "react";
import {
    Card,
    Col,
    Container,
    FloatingLabel,
    Form,
    FormControl,
    Row, Table
} from "react-bootstrap";
import ContentPane from "../../../contentPane/contentPane";

export default class GHNuteCalc extends Component {

    static title = "General Hydroponics Dosing Calculator"
    static description = "This calculator provides a quick access way to calculate your dosing mixture for DWC and (probably) Cocoa grows"

    static conversionFactorTspToMl = 4.929  // Number of ML in a Tsp
    static conversionFactorGalToLitre = 3.79  // Number of L in a G, per the GH Feed Sheet

    static feedingCharts = [
        {
            "name": "Aggressive",
            "value": require("./charts/chart.aggressive.json")
        },
        {
            "name": "Normal",
            "value": require("./charts/chart.medium.json")
        },
        {
            "name": "Light",
            "value": require("./charts/chart.light.json")
        },
    ]

    constructor(props) {
        super(props);

        this.state = {
            errors: {
                batchSizeInvalid: false
            },
            options: {
                baseChart: "Aggressive",  // Must match one of the .values from a this.constructor.feedingCharts
                batchSize: 5,
                batchSizeUnits: "Gal",  // One of Gal or L
                growthStage: "eg",
                batchStrength: 100, // Percentage multiplier
                dosingUnits: "ml",  // One of ml or tsp
            }
        }

        this.updateOptions = this.updateOptions.bind(this)
        this.updateErrors = this.updateErrors.bind(this)
        this.calcValue = this.calcValue.bind(this)
        this.setBaseChart = this.setBaseChart.bind(this)
        this.setBatchSizeUnits = this.setBatchSizeUnits.bind(this)
        this.setDosingUnit = this.setDosingUnit.bind(this)
        this.setBatchSize = this.setBatchSize.bind(this)
        this.setGrowthStage = this.setGrowthStage.bind(this)
        this.setBatchStrength = this.setBatchStrength.bind(this)
    }

    // Helper to update the
    updateOptions(key, value) {
        this.setState((prev) => ({
            options: update(prev.options, {[key]: {$set: value}})
        }))
    }

    updateErrors(key, value) {
        this.setState((prev) => ({
            errors: update(prev.errors, {[key]: {$set: value}})
        }))
    }

    // Calculates the value, with some nicer rounding where appropriate
    calcValue(value) {
        // `value` is the raw value from our chart def, mls/gallon

        // Assume briefly we have Gallons (we'll short-circuit this later)
        //  Round to nearest whole if we have a 5 gallon batch size or larger
        let round = this.state.options.batchSize >= 5

        // If we have our batchsize set to L, convert it back to gallons (since dosing instructions are in ml/gal)
        let mlsToDose = this.state.options.batchSize * value  // Gallons * (ml per gallon)
        if (this.state.options.batchSizeUnits === "L") {
            let gals = this.state.options.batchSize / this.constructor.conversionFactorGalToLitre
            mlsToDose = gals * value

            // Clobber our rounding bool since L != Gal
            round = gals >= 5
        }

        // We now know how many Millis to dose, regardless of batch units
        //  Return TSP measurement if desired
        if (this.state.options.dosingUnits === "tsp") {
            let tsp = (mlsToDose / this.constructor.conversionFactorTspToMl) * (this.state.options.batchStrength / 100)
            return round ? Math.round(tsp) : Math.round(tsp * 10) / 10  // Round to nearest tenths
        }

        // Return our millis dosing volume
        mlsToDose = mlsToDose * (this.state.options.batchStrength / 100)
        return round ? Math.round(mlsToDose) : Math.round(mlsToDose * 10) / 10 // Round to nearest tenths
    }

    // Change which chart we're using as a base
    setBaseChart(e) {
        this.updateOptions("baseChart", e.target.value)
    }

    setBatchSizeUnits(e) {
        this.updateOptions("batchSizeUnits", e.target.value)
    }

    setDosingUnit(e) {
        this.updateOptions("dosingUnits", e.target.value)
    }

    setBatchSize(e) {
        e.target.validity.valid ?
            this.setState((prev) => ({
                options: update(prev.options, {batchSize: {$set: e.target.value}}),
                errors: update(prev.errors, {batchSizeInvalid: {$set: false}})
            })) :
            this.updateErrors("batchSizeInvalid", true)
    }

    setGrowthStage(e) {
        this.updateOptions("growthStage", e.target.value)
    }

    setBatchStrength(e) {
        this.updateOptions("batchStrength", e.target.value)
    }

    render() {

        // Get the base table, for the given aggressiveness
        let growthStageTable = this.constructor.feedingCharts.find(obj => {
            return obj.name === this.state.options.baseChart
        }).value.find(tab => {
            return tab.slug === this.state.options.growthStage
        })

        // Lets just do our math here so it's easy and condensed
        let valMicro = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "micro").value)
        let valGro = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "gro").value)
        let valBloom = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "bloom").value)

        let valCali = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "cali").value)
        let valFPlus = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "fplus").value)
        let valKBloom = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "koolbloom").value)

        let valRstart = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "rstart").value)
        let valRipen = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "ripen").value)
        let valArmorsi = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "armorsi").value)
        let valKleen = this.calcValue(growthStageTable.nutrients.find(nute => nute.slug === "kleen").value)

        return <ContentPane>
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Header>Settings</Card.Header>
                        <Card.Body>
                            <Form>

                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Select a base chart" className="mb-3">
                                            <Form.Select
                                                onChange={this.setBaseChart}
                                                defaultValue={this.state.options.baseChart}
                                            >
                                                {
                                                    this.constructor.feedingCharts.map(c => {
                                                        return <option value={c.name} key={c.name}>{c.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingInputGrid" label="Batch Size">
                                            <FormControl
                                                placeholder="Enter batch size"
                                                value={this.state.options.batchSize}
                                                onChange={this.setBatchSize}
                                                pattern="[0-9]*"
                                                type={"number"}
                                                isInvalid={this.state.errors.batchSizeInvalid}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Batch size must be a number
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Volume Unit" className="mb-3">
                                            <Form.Select
                                                onChange={this.setBatchSizeUnits}
                                            >
                                                <option value="Gal">Gallons</option>
                                                <option value="L">Liters</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Growth stage" className="mb-3">
                                            <Form.Select
                                                onChange={this.setGrowthStage}
                                            >
                                                {
                                                    this.constructor.feedingCharts.find(obj => {
                                                        return obj.name === this.state.options.baseChart
                                                    }).value.map(c => {
                                                        return <option value={c.slug} key={c.slug}>{c.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Batch Strength">
                                            <Form.Select
                                                onChange={this.setBatchStrength}
                                                value={this.state.options.batchStrength}
                                            >
                                                <option value="25">Weak (25%)</option>
                                                <option value="50">Mild (50%)</option>
                                                <option value="75">Medium (75%)</option>
                                                <option value="100">Full (100%)</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label="Dosing Unit" className="mb-3">
                                            <Form.Select
                                                onChange={this.setDosingUnit}
                                            >
                                                <option value="ml">Milliliters</option>
                                                <option value="tsp">Teaspoons</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Form>

                            <Row className={"mt-3"}><Col><h5>Solution Metrics</h5><hr /></Col></Row>
                            <Row className="g-2">
                                <Col>
                                    <p>
                                        Keep in mind that after mixing and a minimum of a 15 minute rest period, the
                                        following target metrics should be tested for
                                    </p>
                                    <div>
                                        <Table striped bordered hover size="sm">
                                            <tbody>
                                            <tr>
                                                <td>pH Range</td>
                                                <td align={"right"}>5.5 - 6.2</td>
                                            </tr>
                                                {
                                                    growthStageTable.solutionTargets.map(target => {
                                                        return <tr key={target.name}>
                                                            <td>{target.name}</td>
                                                            <td align={"right"}>
                                                                {target.value}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>Mix Recipe</Card.Header>
                        <Card.Body>

                            <Row>
                                <Col>
                                    <h5>Base Nutrients</h5>
                                    <hr />
                                </Col>
                            </Row>

                            <Row className="g-2">
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"FloraMicro ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valMicro} isValid={valMicro > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"FloraGro ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valGro} isValid={valGro > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"FloraBloom ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valBloom} isValid={valBloom > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row className={"mt-3"}><Col><h5>ProSeries Additives</h5><hr /></Col></Row>
                            <Row className="g-2">
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"CALiMAGic ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valCali} isValid={valCali > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"Floralicious Plus ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valFPlus} isValid={valFPlus > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"Liquid KoolBloom ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valKBloom} isValid={valKBloom > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row className={"mt-3"}><Col><h5>Misc Additives</h5><hr /></Col></Row>
                            <Row className="g-2">
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"Rapidstart ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valRstart} isValid={valRstart > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"Ripen ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valRipen} isValid={valRipen > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"Armor Si ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valArmorsi} isValid={valArmorsi > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingTextarea" label={"FloraKleen ("+this.state.options.dosingUnits+")"} className="mb-3">
                                        <Form.Control value={valKleen} isValid={valKleen > 0 || null} />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    The base chart values for this calculator were pulled off of the <a href={"https://edge.generalhydroponics.com/www/uploads/20210121184307/FloraSeries-Custom-Feed-Charts.pdf"} target={"_top"}>feeding chart from the GH website</a>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ContentPane>
    }

}
